/* -----------------------------------------
Styles to support AmplitudeJS audio player.

Version: December 2019
------------------------------------------*/

/* PLAYER */

div#amplitude-player {
  	background: $white;
  	margin: $global-margin auto $global-margin+2rem auto;
  	border: 1px solid $medium-gray;
  	display: flex;
  	max-width: 100%;

	@include breakpoint(small only) {
		flex-direction: column;
  	}

	@include breakpoint(medium up) {
    	max-height: 20rem;
    	overflow: auto;
  	} 
}

/* Player left */

div#amplitude-left {
	background: #f1f1f1;
	padding-top: $global-padding + 2rem;
	width: 50%;
	max-height: 20rem;
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid $medium-gray;

	@include breakpoint(small only) {
		width: 100%;
	}

	@include breakpoint(medium) {
		border-right: 1px solid $medium-gray;
		border-bottom: none;
	}

  	img {
    	width: 100%;
  	}

  	div#player-left-bottom {
    	flex: 1;
    	background-color: #f1f1f1;
    	padding: $global-padding 10px;

    div#volume-container:after {
		content: "";
		display: table;
		clear: both;
		}
	}
}

/* Player right */

div#amplitude-right {
	padding: 0;
	overflow-y: scroll;
	width: 50%;
	max-height: 20rem;
	display: flex;
	flex-direction: column;

	@include breakpoint(small only) {
		width: 100%;
	}

	h5 {
		padding-left: $global-padding;
	}

  	div.song {
		cursor: pointer;
		padding: 10px;

    div.song-now-playing-icon-container {
		float: left;
		width: 20px;
		height: 20px;
		margin-right: 10px;

	img.now-playing {
		display: none;
		margin-top: 15px;
	}
}

div.play-button-container {
	display: none;
	background: url('../images/audioplayer/list-play-light.png') no-repeat;
	width: 22px;
	height: 22px;
	margin-top: 10px;
}

div.play-button-container:hover {
	background: url('../images/audioplayer/list-play-hover.png') no-repeat;
}

&.amplitude-active-song-container {
	div.song-now-playing-icon-container {
		img.now-playing {
			display: block;
		}
	}

	&:hover {
		div.play-button-container {
			display: none;
		}
	}
}

div.song-meta-data {
	float: left;
	width: calc( 100% - 110px );

	span.song-title {
		color: $dark-gray;
		font-size: rem-calc(16);
		font-weight: 700;
		display: block;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	span.song-artist, span.song-artist-album {
		color: $dark-gray;
		font-size: rem-calc(12);
		text-transform: uppercase;
		display: block;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

span.song-duration {
	float: left;
	width: 55px;
	text-align: center;
	line-height: 1;
	color: $medium-gray;
	font-size: rem-calc(16);
	font-weight: 500;
	}
}

div.song:after {
	content: "";
	display: table;
	clear: both;
	}
}

/* META-CONTAINER */

div#meta-container {
	text-align: center;
	margin-top: 0.25rem;
	padding: 2rem;

  	span.song-name {
    	display: block;
    	color: $dark-gray;
    	font-size: rem-calc(18);
    	font-weight: 700;
    	font-family: $body-font-family;
    	white-space: nowrap;
    	overflow: hidden;
    	text-overflow: ellipsis;
  	}
  	
	div.song-artist-album {
    	color: $dark-gray;
    	font-size: rem-calc(12);
    	font-weight: 700;
    	text-transform: uppercase;
    	font-family: $body-font-family;

    span {
		display: block;
		}
	}
}

/* Time container */

div#time-container {

	span.current-time {
		color: $medium-gray;
		font-size: rem-calc(12);
		font-weight: 700;
		float: left;
		width: 15%;
		text-align: center;
	}

  	span.duration {
    	color: $medium-gray;
    	font-size: rem-calc(12);
    	font-weight: 700;
    	float: left;
    	width: 15%;
    	text-align: center;
  	}
}

div#time-container:after {
	content: "";
	display: table;
	clear: both;
}

/* Controls */

div#control-container {
	margin-top: 0.5rem;

  	div#repeat-container {
    	width: 25%;
    	float: left;
    	padding-top: $global-padding;

    div#repeat {
		width: 24px;
		height: 19px;
		cursor: pointer;

		&.amplitude-repeat-off {
			background: url('../images/audioplayer/repeat-off.svg');
		}

		&.amplitude-repeat-on {
			background: url('../images/audioplayer/repeat-on.svg');
		}
	}

    div#shuffle {
		width: 23px;
		height: 19px;
		cursor: pointer;
		float: right;

		&.amplitude-shuffle-off{
  			background: url('../images/audioplayer/shuffle-off.svg');
		}

		&.amplitude-shuffle-on{
  			background: url('../images/audioplayer/shuffle-on.svg');
		}
    }
}

div#central-control-container {
	width: 50%;
	margin-top: $global-margin;
	float: left;

	div#central-controls {
		width: 150px;
		margin: auto;

	div#previous {
		display: inline-block;
		width: 40px;
		height: 40px;
		cursor: pointer;
		background: url('../images/audioplayer/prev.svg');
		background-repeat: no-repeat;
		float: left;
		margin-top: 10px;
		margin-right: 5px;
	}

	div#play-pause {
		display: inline-block;
		width: 60px;
		height: 60px;
		cursor: pointer;
		float: left;

        &.amplitude-paused {
          background: url('../images/audioplayer/play.svg');
        }

        &.amplitude-playing {
          background: url('../images/audioplayer/pause.svg');
        }
	}

	div#next {
		display: inline-block;
		width: 40px;
		height: 40px;
		cursor: pointer;
		background: url('../images/audioplayer/next.svg');
		background-repeat: no-repeat;
		float: left;
		margin-top: 10px;
		margin-left: 5px;
		}
	}
}

div#volume-container {
	width: 25%;
	float: left;
	padding-top: 20px;

	div#shuffle-right {
		width: 23px;
		height: 19px;
		cursor: pointer;
		margin: auto;

		&.amplitude-shuffle-off {
			background: url('../images/audioplayer/shuffle-off.svg');
		}

		&.amplitude-shuffle-on {
			background: url('../images/audioplayer/shuffle-on.svg');
		}
	}
}

div.amplitude-mute {
	cursor: pointer;
	width: 25px;
	height: 19px;
	float: left;

	&.amplitude-not-muted {
  		background: url('../images/audioplayer/volume.svg');
  		background-repeat: no-repeat;
	}

	&.amplitude-muted {
		background: url('../images/audioplayer/mute.svg');
		background-repeat: no-repeat;
		}
	}
}

div#control-container:after {
  content: "";
  display: table;
  clear: both;
}

/* Small only */
@include breakpoint(small only) {
  	div#amplitude-player {
		div#repeat-container {
			div#repeat {
				margin: 0;
				padding: 0;
			}
			div#shuffle {
				display: none;
			}
		}
		div#volume-container {
			div.volume-controls {
				display: none;
			}
			div#shuffle-right {
				margin: 0;
				padding: 0;
				float: right;
			}
		}
  	}
}

/* Medium only */
@include breakpoint(medium) {
	div#amplitude-player {
		div#repeat-container {
			div#repeat {
				display: none;
			}
	
			div#shuffle { 
				display: none;
			}
    	}
    
    	div#volume-container {
			div.volume-controls {
				display: none;
			}

			div#shuffle-right {
        		display: none;
			}
		}
	}
}

/* Large and up */
@include breakpoint(large up) {
	div#amplitude-player {
		div#repeat-container {
			div#repeat {
				display: none;
			}
		}
		div#volume-container {
			div#shuffle-right {
				display: none;
			}
		}
	}
}

/* Progress */

div#progress-container {
	width: 70%;
	float: left;
	position: relative;
	height: 20px;
	cursor: pointer;

	&:hover {
		input[type=range].amplitude-song-slider::-webkit-slider-thumb {
		display: block;
	}
		input[type=range].amplitude-song-slider::-moz-range-thumb {
		visibility: visible;
	}
}

progress#song-played-progress {
	width: 100%;
	position: absolute;
	left: 0;
	top: 8px;
	right: 0;
	width: 100%;
	z-index: 60;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	height: 4px;
	border-radius: 5px;
	background: transparent;
	border: none; /* Needed for Firefox */
}
  @media all and (-ms-high-contrast:none)
  {
	*::-ms-backdrop, progress#song-played-progress {
	 color: $primary-color;
	 border: none;
	 background-color: #333;
    }
 }

@supports (-ms-ime-align:auto) {
	progress#song-played-progress {	
		color: $primary-color;	
		border: none;
    }
}

progress#song-played-progress[value]::-webkit-progress-bar {
	background: none;
	border-radius: 5px;
}

progress#song-played-progress[value]::-webkit-progress-value {
	background-color: $primary-color;
	border-radius: 5px;
}

progress#song-played-progress::-moz-progress-bar {
	background: none;
	border-radius: 5px;
	background-color: $primary-color;
	height: 5px;
	margin-top: -2px;
}

progress#song-buffered-progress {
	position: absolute;
	left: 0;
	top: 8px;
	right: 0;
	width: 100%;
	z-index: 10;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	height: 4px;
	border-radius: 5px;
	background: transparent;
	border: none;
	background-color: #d7dee3;
}

progress#song-buffered-progress[value]::-webkit-progress-bar {
	background-color: #cfd8dc;
	border-radius: 5px;
}

progress#song-buffered-progress[value]::-webkit-progress-value {
    background-color: #78909c;
    border-radius: 5px;
    transition: width .1s ease;
}

progress#song-buffered-progress::-moz-progress-bar {
	background: none;
	border-radius: 5px;
	background-color: #78909c;
	height: 5px;
	margin-top: -2px;
}

  progress::-ms-fill {
      border: none;
  }

  @-moz-document url-prefix() {
    progress#song-buffered-progress{
      top: 9px;
      border: none;
    }
  }

  @media all and (-ms-high-contrast:none)
  {
    *::-ms-backdrop, progress#song-buffered-progress {
	color: #78909C;
	border: none;
	}
}

  @supports (-ms-ime-align:auto) {
    progress#song-buffered-progress {
		color: #78909C;
		border: none;
	}
}

input[type=range] {
	-webkit-appearance: none;
	width: 100%;
	margin: 7.5px 0;
	position: absolute;
	z-index: 9999;
	top: -7px;
	height: 20px;
	cursor: pointer;
	background-color: inherit;
}
  
input[type=range]:focus {
	outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
	width: 100%;
	height: 0px;
	cursor: pointer;
	box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
	background: #0075a9;
	border-radius: 0px;
	border: 0px solid #010101;
}

input[type=range]::-webkit-slider-thumb {
	box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
	border: 1px solid $primary-color;
	height: 15px;
	width: 15px;
	border-radius: 16px;
	background: $primary-color;
	cursor: pointer;
	-webkit-appearance: none;
	margin-top: -7.5px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
	background: $primary-color;
}

input[type=range]::-moz-range-track {
    width: 100%;
    height: 0px;
    cursor: pointer;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
    background: #0075a9;
    border-radius: 0px;
    border: 0px solid #010101;
}

input[type=range]::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    border: 1px solid $primary-color;
    height: 15px;
    width: 15px;
    border-radius: 16px;
    background: $primary-color;
    cursor: pointer;
}

input[type=range]::-ms-track {
    width: 100%;
    height: 0px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
}

input[type=range]::-ms-fill-lower {
    background: #003d57;
    border: 0px solid #010101;
    border-radius: 0px;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
}

input[type=range]::-ms-fill-upper {
    background: #0075a9;
    border: 0px solid #010101;
    border-radius: 0px;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
}

input[type=range]::-ms-thumb {
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    border: 1px solid $primary-color;
    height: 15px;
    width: 15px;
    border-radius: 16px;
    background: $primary-color;
    cursor: pointer;
    height: 0px;
    display: block;
}

/*
  IE 11
*/

@media all and (-ms-high-contrast:none) {
    *::-ms-backdrop, input[type="range"].amplitude-song-slider {
      padding: 0px;
    }

    *::-ms-backdrop, input[type=range].amplitude-song-slider::-ms-thumb {
      height: 15px;
      width: 15px;
      border-radius: 10px;
      cursor: pointer;
      margin-top: -8px;
    }

	*::-ms-backdrop, input[type=range].amplitude-song-slider::-ms-track {
		border-width: 15px 0;
		border-color: transparent;
	}

	*::-ms-backdrop, input[type=range].amplitude-song-slider::-ms-fill-lower {
		background: #CFD8DC;
		border-radius: 10px;
	}

    *::-ms-backdrop, input[type=range].amplitude-song-slider::-ms-fill-upper {
      background: #CFD8DC;
      border-radius: 10px;
    }
}
  
@supports (-ms-ime-align:auto) {
	input[type=range].amplitude-song-slider::-ms-thumb{
		height: 15px;
		width: 15px;
		margin-top: 3px;
	}
}
 
input[type=range]:focus::-ms-fill-lower {
	background: #0075a9;
}

input[type=range]:focus::-ms-fill-upper {
	background: $primary-color;
	}
}

/* VOLUME SLIDER */

input[type=range].amplitude-volume-slider {
	-webkit-appearance: none;
	width: calc( 100% - 33px);
	float: left;
	margin-top: 10px;
	margin-left: 5px;
}

@-moz-document url-prefix() {
	input[type=range].amplitude-volume-slider {
  		margin-top: 0px;
	}
}

@supports (-ms-ime-align:auto) {
	input[type=range].amplitude-volume-slider {
		margin-top: 3px;
		height: 12px;
		background-color: rgba(255,255,255,0) !important;
		z-index: 999;
		position: relative;
	}
  
  	div.ms-range-fix{
    	height: 1px;
    	background-color: #A9A9A9;
    	width: 67%;
    	float: right;
    	margin-top: -6px;
    	z-index: 9;
    	position: relative;
  	}
}

@media all and (-ms-high-contrast:none)
{
  *::-ms-backdrop, input[type=range].amplitude-volume-slider {
	margin-top: -24px;
	background-color: rgba(255,255,255,0) !important;
	}
}

input[type=range].amplitude-volume-slider:focus {
	outline: none;
}

input[type=range].amplitude-volume-slider::-webkit-slider-runnable-track {
	width: 75%;
	height: 1px;
	cursor: pointer;
	animate: 0.2s;
	background: #CFD8DC;
}

input[type=range].amplitude-volume-slider::-webkit-slider-thumb {
	height: 10px;
	width: 10px;
	border-radius: 10px;
	background: $primary-color;
	cursor: pointer;
	margin-top: -4px;
	-webkit-appearance: none;
}

input[type=range].amplitude-volume-slider:focus::-webkit-slider-runnable-track {
	background: #CFD8DC;
}

input[type=range].amplitude-volume-slider::-moz-range-track {
	width: 100%;
	height: 1px;
	cursor: pointer;
	animate: 0.2s;
	background: #CFD8DC;
}

input[type=range].amplitude-volume-slider::-moz-range-thumb {
	height: 10px;
	width: 10px;
	border-radius: 10px;
	background: $primary-color;
	cursor: pointer;
	margin-top: -4px;
}

input[type=range].amplitude-volume-slider::-ms-track {
	width: 100%;
	height: 1px;
	cursor: pointer;
	animate: 0.2s;
	background: transparent;
	/*leave room for the larger thumb to overflow with a transparent border */
	border-color: transparent;
	border-width: 15px 0;

	/*remove default tick marks*/
	color: transparent;
}

input[type=range].amplitude-volume-slider::-ms-fill-lower {
	background: #CFD8DC;
	border-radius: 10px;
}

input[type=range].amplitude-volume-slider::-ms-fill-upper {
	background: #CFD8DC;
	border-radius: 10px;
}

input[type=range].amplitude-volume-slider::-ms-thumb {
	height: 10px;
	width: 10px;
	border-radius: 10px;
	background: $primary-color;
	cursor: pointer;
	margin-top: 2px;
}

input[type=range].amplitude-volume-slider:focus::-ms-fill-lower {
	background: #CFD8DC;
}

input[type=range].amplitude-volume-slider:focus::-ms-fill-upper {
	background: #CFD8DC;
}

input[type=range].amplitude-volume-slider::-ms-tooltip {
	display: none;
}