// Custom CSS for Cocteau Twins Website
// www.cocteautwins.com
// Michael Borum/etherweave, ver Aug 2024

/* IMPORT FONTS */

@font-face {
    font-family: 'LibreBodoniRegular';
    src: url('../fonts/LibreBodoniRegular.otf') format('opentype'),
    	 url('../fonts/LibreBodoniRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'LibreBodoniBold';
    src: url('../fonts/LibreBodoniBold.otf') format('opentype'),
    	 url('../fonts/LibreBodoniBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'LibreBodoniBoldItalic';
    src: url('../fonts/LibreBodoniBoldItalic.otf') format('opentype'),
    	 url('../fonts/LibreBodoniBoldItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'LibreBodoniItalic';
    src: url('../fonts/LibreBodoniItalic.otf') format('opentype'),
    	 url('../fonts/LibreBodoniItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FreeUniversalRegular';
    src: url('../fonts/FreeUniversalRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FreeUniversalBold';
    src: url('../fonts/FreeUniversalBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FreeUniversalItalic';
    src: url('../fonts/FreeUniversalItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

h1, h2, h3, h4, h5, h6 {
	cite, em, i {
		font-family: 'LibreBodoniBoldItalic';
	}
}

/* IMPORT EXTERNAL STYLESHEETS */

@import url('foundation-icons.css');

/* ANIMATION MIXINS */

@mixin animation ($duration) {
		-webkit-animation: fadein ($duration); /* Safari, Chrome and Opera > 12.1 */
	       -moz-animation: fadein ($duration); /* Firefox < 16 */
	        -ms-animation: fadein ($duration); /* Internet Explorer */
	         -o-animation: fadein ($duration); /* Opera < 12.1 */
	            animation: fadein ($duration);

	@keyframes fadein {
	   from { opacity: 0; }
	   to   { opacity: 1; }
	}	
	
	/* Firefox < 16 */
	@-moz-keyframes fadein {
	    from { opacity: 0; }
	    to   { opacity: 1; }
	}
	
	/* Safari, Chrome and Opera > 12.1 */
	@-webkit-keyframes fadein {
	    from { opacity: 0; }
	    to   { opacity: 1; }
	}
	
	/* Internet Explorer */
	@-ms-keyframes fadein {
	    from { opacity: 0; }
	    to   { opacity: 1; }
	}
	
	/* Opera < 12.1 */
	@-o-keyframes fadein {
	    from { opacity: 0; }
	    to   { opacity: 1; }
	}
}

@mixin animation2 ($duration) {
		-webkit-animation: fadeout ($duration); /* Safari, Chrome and Opera > 12.1 */
	       -moz-animation: fadeout ($duration); /* Firefox < 16 */
	        -ms-animation: fadeout ($duration); /* Internet Explorer */
	         -o-animation: fadeout ($duration); /* Opera < 12.1 */
	            animation: fadeout ($duration);

	@keyframes fadein {
	   from { opacity: 1; }
	   to   { opacity: 0; }
	}	
	
	/* Firefox < 16 */
	@-moz-keyframes fadein {
	    from { opacity: 1; }
	    to   { opacity: 0; }
	}
	
	/* Safari, Chrome and Opera > 12.1 */
	@-webkit-keyframes fadein {
	    from { opacity: 1; }
	    to   { opacity: 0; }
	}
	
	/* Internet Explorer */
	@-ms-keyframes fadein {
	    from { opacity: 1; }
	    to   { opacity: 0; }
	}
	
	/* Opera < 12.1 */
	@-o-keyframes fadein {
	    from { opacity: 1; }
	    to   { opacity: 0; }
	}
}

@mixin inlineImage {
	width: auto;
	margin: $global-margin;
	padding: $global-padding;
	border: 1px solid $medium-gray;
	background-color: $white;

	img {
		width: 100%;
		max-width: 100%;
		border: 1px solid $medium-gray;
		margin: 0 auto 0 auto;
		padding: 0;
	}

	figcaption, .videoCaption {
		font-size: rem-calc(12);
		text-align: center;
		margin-top: 1rem;
	}
}

@mixin chrome-styles {
  @media screen and (-webkit-min-device-pixel-ratio:0)
    and (min-resolution:.001dpcm) {
      @content;
  }
}

/* For page loader */

.pace {
	-webkit-pointer-events: none;
	pointer-events: none;

	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}

.pace-inactive {
	display: none;
}

.pace .pace-progress {
	background: #515151;
	position: fixed;
	z-index: 2000;
	top: 0;
	right: 100%;
	width: 100%;
	height: 2px;
}

/* Site syles */

.osano-cm-widget {
	display: none;
	opacity: 0.25;
}

.lazy {
	@include animation(2s);
}

body {
	font-display: auto;
	font-size: rem-calc(16);
	hyphens: auto;

	h1, h2, h3, h4, h5, h6 {
		letter-spacing: 0.5px;
		margin-top: 1.00rem;

		@include breakpoint (medium) {
			margin-top: 1.50rem;
		}
	}

	.socialFooter {
		width: 100%;
		list-style: none;
		font-size: rem-calc(30);
		margin: 0;
		padding: 0;

		@include breakpoint(medium) {
			font-size: rem-calc(36);
		}

		li {
			display: inline-block;
			opacity: 0.70;
			width: 3rem;
			height: 3rem;
			margin: 0;
			padding: 0;
			border-radius: $global-radius;

			&:hover {
				opacity: 1.0;
			}

			a {
				display: block;
				outline: none;
				color: $white;
				width: 100%;
				height: 100%;
			}
		}
	}
}

// Off-canvas site navigation

#offCanvasLeft {
	width: 100%;
	text-align: center;
	outline: 1px solid $white;
	outline-offset: -0.75rem;

	@include breakpoint(large) {
		width: 25%;
	}
}

.top-bar {
	position: absolute;
	top: 1rem; left: 1rem;
	z-index: 999999;
	opacity: 1.0;

	@include breakpoint(medium) {
		opacity: 0.8;
	}

	.menuToggle {
		position: fixed;
		background-color: $dark-gray;
		border: 1px solid $light-gray;
		border-radius: $global-radius;
		outline: none;
		width: 2.35rem;
		margin: 0;
		padding: 0.5rem;

		@include breakpoint(medium) {
			width: 3.25rem;
		}

		button {
			margin: 0;
			padding: 0;

			.menu-icon {
				margin: 0;
				padding: 0;
				font-weight: bold;
				line-height: 100%;
				outline: none;
			}
		}

		@include breakpoint(medium) {
			padding: 1rem;
		}
	}
}

nav {
	width: 100%;
	margin: 0 auto 0 auto;
	padding: 5rem 0 0 0;

	ul.menu.vertical {
		list-style: none;
		width: 90%;
		font-size: rem-calc(18);
		font-family: 'LibreBodoniItalic';
		text-align: center;
		letter-spacing: 0.5px;
		padding: 0.5rem;
		margin: 0 auto 0 auto;
	
		@include breakpoint(medium) {
			font-size: rem-calc(16);
		}
	
		@include breakpoint(medium only) {
			padding-top: 5rem;
		}
	
		a {
			color: $white;
	
			&:hover {
				color: $medium-gray;
			}
		}
	}
}

.close-button {
	padding-top: 0.40rem;
	border: none;
	outline: none;

	span {
		font-size: rem-calc(16);
		color: $white;
		padding-right: 1rem;

		@include breakpoint(medium) {
			padding-right: 2rem;
		}
	}
}

.somersault-lily {
	width: 100%;
	text-align: center;
		
	img {
		margin-top: 1.5rem;
		opacity: 0.5;
	}
}

.off-canvas-content {
	background: url('../images/header-bg-sm.png') top right no-repeat, url('../images/footer-bg-sm.png') bottom left no-repeat;
	margin: 0;
	padding: 0;
	overflow: hidden;

	@include breakpoint(medium) {
		background: url('../images/header-bg.png') top right no-repeat, url('../images/footer-bg.png') bottom left no-repeat;
	}
}

// Home Page styling featuring background video

.homePage {
	background: $dark-gray; 
	overflow: hidden;

	.intro {
		position: relative;
		height: 100vh;
		width: 100%;
		max-width: 100%;
		display: table;
		background: #eee;
		overflow: hidden;
		}
	
	.intro-content {
		display: table-cell;
		vertical-align: middle;
		text-align: center;
		padding: 0;
		background: #eee;
		}
	
	.siteLogo {
		position: relative;
		z-index: 999998;
		margin-top: -10rem;

		@include breakpoint(medium) {
			margin-top: 0;
		}

		object {
			width: 75%;
			height: 75%;

			@include breakpoint(medium) {
				width: 50%;
				height: 50%;
				}
			}
		}
	
	.siteLogo, video {
		@include animation(5s);
	}
	
	.bg-video-wrapper {
		/* background cover video */
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		overflow: hidden;
		background-size: cover;
		margin: 0;
		padding: 0;
		}

	.bg-video-wrapper video {
		display: block;
		position: absolute;
		left: 50%;
		top: 50%;
		bottom: auto;
		right: auto;
		-webkit-transform: translateX(-50%) translateY(-50%);
		-moz-transform: translateX(-50%) translateY(-50%);
		-ms-transform: translateX(-50%) translateY(-50%);
		-o-transform: translateX(-50%) translateY(-50%);
		transform: translateX(-50%) translateY(-50%);
		min-height: 100%;
		min-width: 100%;
		max-width: none;
		height: auto;
		width: auto;
		margin: 0;
		padding: 0;
		}
	
	footer {
		clear: both;
		position: relative;
		z-index: 999998;
		width: 100%;
		font-size: rem-calc(9);
		text-align: center;
		margin: -14.5rem auto 0 auto;

		.dedication {
			font-family: 'LibreBodoniItalic';
			margin-bottom: 1rem;
		}

		@include breakpoint(medium) {
			font-size: rem-calc(11);
		}

		p {
			color: $white;
			padding: 0 1rem 0 1rem;
			margin: 0.25rem 0 0 0;

			@include breakpoint(medium) {
				padding: auto;
			}
		}
		
		img {
			margin-bottom: 1rem;
		}
	}
}

// Search Engine styles

#searchModal {
	@include animation(2s);
	background-color: $white;
	color: $dark-gray;

	input[type=submit] {
		float: right;
	}

	.close-button {
		color: $dark-gray;
		margin: 0;
		padding: 0;
	}

	.simplesearch-search-form {
		padding: 1rem;
	}
}

.simplesearch-result {
	h4 {
		margin-bottom: 0;
	}

	p {
		margin: 0;
		padding: 0;
	}

	.searchResultsContentType {
		font-size: rem-calc(12);
		font-weight: bold;
		text-transform: uppercase;
		padding: 0;
	}
}

.simplesearch-paging {
	margin-top: 2rem;
}

// Basic Content Page styles -- Specific page type styles handled separately

.contentPage {
	background: $white;

	header {
		width: 100%;
		min-height: 5rem;
		text-align: center;

		@include breakpoint(large) {
			min-height: 12rem;
		}

		.siteLogo {
			min-height: 100%;
			margin: 1.5rem auto 1rem auto;
			padding: 0;
			@include animation(3s);

			object {
				width: 50%;
				height: 50%;

				@include breakpoint(medium) {
					width: 40%;
					height: 40%;
				}

				@include breakpoint(large) {
					width: 30%;
					height: 30%;
				}

				@include breakpoint(xxlarge) {
					width: 25%;
					height: 25%;
				}
			}
		}
	}

	main {
		width: 100%;
		background: $light-gray;
		border-top: 1px solid $dark-gray;
		border-bottom: 1px solid $dark-gray;
		margin: 0 auto 0 auto;
		padding: 1rem;

		@include breakpoint(medium) {
			padding: 0 0 2.5rem 0;
		}
	}

	article {
		width: 90%;
		margin: 0 auto 0 auto;

		@include breakpoint(medium) {
			width: 80%;
		}

		@include breakpoint(large) {
			width: 60%;
		}
	}

	.breadcrumbs { // Overall breadcrumb container
		width: 100%;
		display: block;
		font-size: rem-calc(10);
		text-transform: uppercase;
		margin: 0;
		padding: 1.25rem 0 0 0;

		@include breakpoint(medium) {
			font-size: rem-calc(11);
			letter-spacing: 0.25px;
		}
	}
	
	#breadcrumb { // The list of breadcrumb links
		width: 100%;
		list-style: none;
		white-space: nowrap;
		overflow: hidden;
        text-overflow: ellipsis;
		margin: 0;
		padding: 0;

		li { // The individual breadcrumb links
			display: block;
			margin: 0;
			padding: 0 0 0.5rem 0;

			&:first-child { // So the separator doesn't precede the HOME crumb
				&:before {
					content: '';
					margin: 0;
				}
			}

			@include breakpoint(medium) {
				display: inline-block;
				padding: 1.25rem 0 1.25rem 0;
			}
		}
	}

	.inlineImage {
		@include inlineImage;
	}

	.historyTOC {
		background: $white;
		margin: $global-margin $global-margin 2rem $global-margin;
		padding: $global-padding;
		border: 1px solid $medium-gray;

		&:first-of-type {
			margin-top: 2rem;
		}

		.chapterNumber {
			position: relative; top: -1.85rem; left: -2.25rem;
			background: $primary-color;
			width: 3.0rem;
			height: 3.0rem;
			border-radius: 50%;
			color: $white;
			font-family: $header-font-family;
			font-size: rem-calc(18);
			line-height: 1;
			text-align: center;
			margin: 0;
			padding: 1rem;
		}

		h4 {
			margin-top: 0;
			padding-top: 0;
		}

		.more-link {
			font-size: rem-calc(14);
		}
	}

	// Press and News Styles

	.pressClip {
		article.cell {
			p:first-of-type {
				font-size: rem-calc(18);
				font-weight: 700;
			}

			figure {
				@include inlineImage;
			}
		}
	}

	.pressNewsPost {
		margin: 1rem 0 2rem 0;
		padding: 0;

		.pressNewsTitle {
			margin-top: 0;
			padding: 0;
		}

		.pressNewsImage {
			margin: 0;
			padding: 0;
		}
	}

	// Footer 

	footer {
		width: 80%;
		font-size: rem-calc(9);
		text-align: center;
		margin: 2rem auto 2.5rem auto;
		padding: 0;

		@include breakpoint(medium) {
			font-size: rem-calc(11);
		}

		img {
			margin-bottom: 1rem;
		}

		.socialFooter {
			a {
				color: $dark-gray;
			}
		}
	}
}

.songIndex, .bioCV {
	font-size: rem-calc(10);
	margin: $global-margin 0 2rem 0;

	th {
		text-align: left;
	}

	@include breakpoint(medium) {
		width: 100%;
		font-size: rem-calc(11);
	}

	@include breakpoint(large) {
		font-size: rem-calc(12);
	}
}

.bioCV {
	th {
		width: 30%;

		&:last-of-type {
			width: 10%;
		}
	}
}

.songIndex {
	th {
		width: 30%;

		&:nth-child(2) {
			width: 50%;
		}

		&:nth-child(3) {
			width: 10%;
		}

		&:nth-child(4) {
			width: 10%;
		}
	}
}

.discogIndex, .bioIndex, .bookIndex, .filmIndex, .cocteauFestIndex {
	list-style: none;
	margin-top: 2rem;
	padding: 0;
}

.archiveIndex {
	list-style: none;
	font-size: rem-calc(18);
	margin: 0 0 2rem 0;
	padding: 0;

	li {
		margin-bottom: 0.5rem;
	}

	@include breakpoint(medium) {
		font-size: rem-calc(16);

		li {
			margin-bottom: 0;
		}
	}
}

.discogListing, .bioListing, .bookListing, .eventListing, .filmListing, .cocteauFestListing {
	margin: 0 0 2rem 0;

	@include breakpoint(medium) {
		margin: 0 0 1rem 0;
	}

	img {
		border: 1px solid $medium-gray;
		margin: 0;
		padding: 0.25rem;
		width: 100%;
		max-width: 100%;

		&:hover, &:active {
			border: 1px solid $dark-gray;
		}
	}

	ul, li {
		list-style: none;
		font-size: rem-calc(14);
		margin: 0;
		padding: 0;

		@include breakpoint(medium only) {
			font-size: rem-calc(12);
		}
	}

	h4 {
		font-size: rem-calc(18);
		margin-top: 0.5rem;
		padding-top: 0;

		@include breakpoint(medium only) {
			font-size: rem-calc(14);
		}
	}

	h4, ul {
		list-style: none;
		padding: 0 0 0 0.25rem;
	}

	.bioIntro {
		font-size: rem-calc(14);
		padding: 0.25rem;
	}
}

// Miscellaneous list styles

.links, .contacts, .pressClipDetail, .pressArchives, .onlineShops, .reviews {
	list-style: none;
	margin: 0;

	li {
		margin-bottom: 0.5rem;
	}

	@include breakpoint(medium) {

		li {
			margin-bottom: 0;
		}
	}
}

.generic {
	list-style: none;
	margin-left: 1.5rem;
}

.press {
	margin-left: 0;
	margin-bottom: 2rem;

	dt {
		display: block;
		font-weight: bold;
		margin-bottom: 0;
	}

	dd {
		display: block;
		font-size: rem-calc(14);
		font-weight: bold;
		text-transform: uppercase;
		margin-bottom: 1rem;

		@include breakpoint(large) {
			font-size: rem-calc(12);
		}
	}
}

.formats, .performers, .onlineShops, .reviews, .links {
	margin-left: 0;
}

.archiveLinks {
	dt {
		padding: 1rem 0 0 0;
		margin: 0;
	}
}

// CocteauFest styles

.cocteauFestSnapshot {
	img {
		display: block;
		width: 100%;
		max-width: 100%;
		margin-top: 1rem;
		padding: 0.5rem;
		border: 1px solid $medium-gray;

		@include breakpoint(medium) {
			margin-top: 0;
		}
	}

	ul {
		list-style: none;
		margin: 1rem 0 0 0;
		padding: 0;

		@include breakpoint(medium) {
			font-size: rem-calc(20);
		}
	}

	p {
		margin: 1rem 0 0 0;
	}
}

.cocteauFestGallery {
	margin: 1rem 0 0 0;


	@include breakpoint(medium) {
		padding: 1rem 0 0 0;
		clear: both;
	}

	img {
		padding: 1rem;
	}
}

.discography, .biography, .history, .archiveBooks, .archiveFilms { // Styles for Discography, Biography, History, Press, and some Archive pages

	.sleeveImage, .bioPhoto {
		margin: 1rem 0 1.5rem 0;

		img {
			width: 100%;
			border: 1px solid $medium-gray;
			padding: 0.5rem;
		}

		figcaption {
			font-size: rem-calc(12);
			text-align: center;
			text-transform: uppercase;
			letter-spacing: 0.5px;
			margin-top: 0.5rem;
		}
	}

	#slideshow {
		display: block;
		font-size: rem-calc(12);
		margin: 1rem auto 0 auto;
	}

	.bookImage, .filmImage {
		margin: 1rem 0 1.5rem 0;
		text-align: center;

		img {
			width: 100%;
			max-width: 100%;
			border: 1px solid $medium-gray;
			padding: 0.5rem;

			@include breakpoint(medium) {
				width: 60%;
			}
		}

		figcaption {
			font-size: rem-calc(12);
			text-align: center;
			text-transform: uppercase;
			letter-spacing: 0.5px;
			margin-top: 0.5rem;
		}

	}

	.bioSocial {
		margin: 1rem 0 1rem 0;
		padding: 0;

		a {
			font-size: rem-calc(28);
			margin: 0 0.5rem 0 0;
			padding: 0;
		}
	}

	.releaseTitle, .memberName, .bookTitle, .filmTitle {
		font-family: 'LibreBodoniItalic';
		font-size: rem-calc(36);

		&.memberName {
			font-family: 'LibreBodoniBold';
		}

		@include breakpoint(medium) {
			font-size: rem-calc(50);
		}

		@include breakpoint(large) {
			font-size: rem-calc(56);
		}
	}

	&.history {

		.callout {
			&:first-child {
				margin: 2rem 0 0 0;

				p {
					padding: 2rem;

					&.with-attribution {
						padding: 2rem 2rem 1rem 2rem;
					}
				}
			}
		}

		.historyMedia {
			@include inlineImage;
		}
	}

	.callout {
		margin: 0 0 3rem 0;
		padding: 0;

		@include breakpoint(medium) {
			margin: 0;
			padding: 2rem;
		}

		p {
			background: $white;
			border: 1px solid $medium-gray;
			font-family: 'LibreBodoniItalic';
			font-size: inherit;
			text-align: justify;
			padding: 2rem 2rem 1rem 2rem;
			margin: 0;

			&:not(:first-child) {
				margin-top: 2rem;
			}

			@include breakpoint(medium) {
				font-size: rem-calc(18);
			}
		}

		.attribution {
			position: relative; top: 1.94rem;
			display: block;
			white-space: nowrap;
			color: $white;
			border: 1px solid $medium-gray;
			background: $dark-gray;
			width: auto;
			max-width: 50%;
			height: fit-content;
			text-align: center;
			text-transform: uppercase;
			font-family: 'FreeUniversalBold';
			font-size: rem-calc(11);
			margin: 0 auto 0 auto;
			padding: 0.25rem;

			@include breakpoint(medium) {
				position: relative; bottom: -2.3rem;
			}
		}
	}

	.releaseDetail, .bioDetail, .bookDetail, .filmDetail {
		list-style: none;
		margin: 1.5rem 0 0 0;

		@include breakpoint(medium) {
			margin: 0;
		}

		&.bioDetail {
			margin-bottom: 1rem;

			.callout {
				p {
					padding: 2rem;
				}
			}
		}

		&.releaseDetail {
			.callout {
				p {
					&.editorial {
						padding: 2rem;
						font-family: $body-font-family;
						text-align: left;
					}
				}
			}
		}

		ol { // Track listings
			margin-left: 1.25rem;

			li.deepList {
				font-weight: bold;

				ul li {
					font-weight: normal;
				}
			}

			ul {
				margin-bottom: 1rem;
			}
		}

		.formats li, .performers li {
			display: inline-block;
			&:after {
				content: ' | ';
			}
			
			&:last-child {
				&:after {
					content: none;
				}
			}
		}

		ul.releaseNotes, ul.moreNotes {
			list-style: square;
			margin-left: 1.25rem;

			&.moreNotes {
				padding-top: 0.50rem;

				@include breakpoint(medium) {
					padding-top: 0;
				}
			}

			li {
				margin-bottom: 1rem;

				&.deepList {
					list-style: none;
					margin: 0 0 1rem 0;
				}

				@include breakpoint(medium) {
					margin-bottom: 0;
				}

				ul {
					list-style: square;
				}
			}
		}

		.toggleNotes, .toggleBio {
			&:before {
				content: '...';
				display: block;
				margin-top: -1rem;
				color: $dark-gray;
			}
		}
		
		#more {
			display: none;
		}

		ul.moreNotes {
			list-style: square;
			margin: -1rem 0 0 1.25rem;
		}

		pre {
			font-size: rem-calc(12);
			margin-bottom: 1.5rem;

			@include breakpoint(medium) {
				font-size: rem-calc(14);
				padding-left: 2rem;
			}
		}
	}

	.media { // Media section on Discography pages

		.audio { // Embeds AppleMusic Player
			width: 100%;
			border: 1px solid $medium-gray;
			margin: 0;
			padding: 0.25rem 0.25rem 0 0.25rem;

			@include breakpoint(medium) {
				width: 93%;
			}
		}

		.digitalBuy { // Links to digital music sites, displayed as icons
			display: block;
			margin: 0.25rem auto 0 auto;

			ul {
				list-style: none;
				text-align: center;
			}

			li {
				display: inline-block;
				width: 40px;
				height: 40px;
				opacity: 0.8;
				margin: 0.5rem 0 0 0;
				padding: 0;
				border-radius: $global-radius;

				@include breakpoint(medium) {
					width: 73px;
					height: 73px;
					margin: 0 0 0.25rem -2.0rem;
				}

				&.amazonLink {
					background: #333 url('../images/misc/music-svc-icon-sprite-sm.svg') no-repeat 10px 8px;

					@include breakpoint(medium) {
						background: #333 url('../images/misc/music-svc-icon-sprite.svg') no-repeat 15px 13px;
					}
				}

				&.appleLink {
					background: #333 url('../images/misc/music-svc-icon-sprite-sm.svg') no-repeat -26px 8px;

					@include breakpoint(medium) {
						background: #333 url('../images/misc/music-svc-icon-sprite.svg') no-repeat -58px 12px;
					}
				}

				&.youTubeLink {
					background: #333 url('../images/misc/music-svc-icon-sprite-sm.svg') no-repeat -62px 8px;

					@include breakpoint(medium) {
						background: #333 url('../images/misc/music-svc-icon-sprite.svg') no-repeat -130px 12px;
					}
				}

				&.spotifyLink {
					background: #333 url('../images/misc/music-svc-icon-sprite-sm.svg') no-repeat -98px 8px;

					@include breakpoint(medium) {
						background: #333 url('../images/misc/music-svc-icon-sprite.svg') no-repeat -205px 12px;
					}
				}

				&.tidalLink {
					background: #333 url('../images/misc/music-svc-icon-sprite-sm.svg') no-repeat -134px 8px;

					@include breakpoint(medium) {
						background: #333 url('../images/misc/music-svc-icon-sprite.svg') no-repeat -277px 13px;
					}
				}

				&:hover {
					opacity: 1.0;
				}

				a {
					display: block;
					height: 100%;
					width: 100%;
				}
			}
		}

		// Video displays for both Discography and Biography pages, but NOT Audio & Video page

		.video, #videoSlider iframe {
			border: 1px solid $medium-gray;
			margin: 0;
			padding: 0.25rem;
		}

		.orbit, #videoSlider {
			&:hover {
				.orbit-caption {
					visibility: hidden;
				}
			}
		}

		.videoCaption {
			font-size: rem-calc(11);
			margin: 1rem 0 0 0.5rem;
		}
	}
}

.audioVideo { // Audio & Video Page

	.tabs {
		margin-top: 1rem;

		@include breakpoint(medium) {
			margin-top: 0;
		}
	}

	.tabs-title {
		h3 {
			margin: 0;
			padding: 0;
		}

		a {
			outline: none;
		}
	}

	.playlists {
		margin: 0;
		padding: 1rem 0 1rem 0;
	}

	.videoGallery {
		list-style: none;
		margin: 0 auto 0 auto;
		padding: 0;

		&:first-child {
			padding-top: 0;
		}

		.videoListing {
			width: 100%;
			text-align: center;
			margin: 0.25rem;
			padding: 0;

			.videoThumb {
				width: 100%;
				max-width: 100%;
				margin: 0;
				padding: 0.25rem;
				outline: none;
				border: 1px solid $medium-gray;
				
				@include breakpoint(large) {
					opacity: 0.70;
			
					&:hover {
						cursor: pointer;
						opacity: 1.0;
						border: 1px solid $dark-gray;
					}
				}
			}

			.video-caption {
				position: relative; top: -1rem;
				display: block;
				white-space: nowrap;
				width: auto;
				background: $white;
				border: 1px solid $medium-gray;
				max-width: 85%;
				height: fit-content;
				text-align: center;
				font-family: 'FreeUniversalBold';
				font-size: rem-calc(12);
				color: $dark-gray;
				margin: 0 auto 0 auto;
				padding: 0.25rem;

				@include breakpoint(medium only) {
					position: relative; bottom: -2.3rem;
					font-size: rem-calc(10);
				}
			}
		}
	}

	.toggleVideos, .toggleLiveVideos, .toggleInterviewVideos {
		display: block;
	}
		
	#moreVideos, #moreLiveVideos, #moreInterviewVideos {
		display: none;
		margin-top: 0;
		padding-top: 0;
	}
}

// Accordion styles for Press & FAQ pages

a.accordion-title {
	line-height: 150%;
}

div.accordion-content {
	border: 1px solid $medium-gray;
	background: $white;

	.LeesaBeales {
		width: 100%;
		max-width: 100%;
		margin-bottom: $global-margin;
		padding: 0;

		@include breakpoint(medium) {
			float: left;
			width: 30%;
			height: auto;
			margin: 0 $global-margin 0 0;
		}

		figcaption {
			font-size: rem-calc(12);
		}
	}

	p {
		&:last-of-type {
			margin-bottom: 0;
			padding-bottom: 0;
		}
	}

	div.responsive-embed, ul {
		margin-top: 1rem;
	}
}

.pressAccordion {
	.accordion-title {
		color: $dark-gray;
		font-size: rem-calc(22);
		font-family: $header-font-family;

		@include breakpoint(medium) {
			font-size: rem-calc(28);
		}

		@include breakpoint(large) {
			font-size: rem-calc(24);
		}
	}
	.accordion-content {
		border: none;
		background: none;
	}
}

// Quote styles for Lyrics page

.lyrics {
	background: $white;
	border: 1px solid $dark-gray;
	padding: 1rem;
}

// Masonry styles for Fan-Contributed Artwork page

$masonry-css-column-gap: $global-margin;

.masonry-css {
	column-count: 1;
	column-gap: $masonry-css-column-gap;

	@include breakpoint(400px) {
  	column-count: 2;
	}

	@include breakpoint(600px) {
  	column-count: 3;
	}

	@include breakpoint(800px) {
  	column-count: 4;
	}

	@include breakpoint(1100px) {
  	column-count: 5;
	}
}

.masonry-css-item {
	break-inside: avoid;
	margin-bottom: $masonry-css-column-gap;
}

// Card styles for Fan-Contributed Artwork page

.fanartAudio {
	background: $white;
	width: 12rem;
	margin: 1rem;
	padding: 1rem;
	text-align: center;

	audio {
		margin: 1rem;
		padding: 1rem;
	}

	.fanartAudioPlay, .fanartAudioPause {
		font-size: rem-calc(40);
		color: $primary-color;
		padding: 1rem;

		&:hover {
			color: $dark-gray;
		}
	}
}

.card {
    position: relative;
    padding: 0;
    margin: 0;
    -webkit-perspective: 5000;
    perspective: 5000;

    .container {
    	width: 100%;
    	height: 100%;
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
        -webkit-transition: 0.6s;
        transition: 1.0s;
        
        &:hover {
            -webkit-transform: rotate3d(0, 1, 0, 180deg);
            transform: rotate3d(0, 1, 0, 180deg);
        }

        .front {
        	width: 100%;
    		height: 100%;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            z-index: 2;
            padding: 1em;
        }

        .back {
        	width: 100%;
    		height: 100%;
            background-color: $white;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            position: absolute;
            left: 0;
            top: 0;
            overflow-y: scroll;
            -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
            padding: 1rem;
            font-size: rem-calc(14);

            h6 {
                font-weight: normal;
            }
        }
    }
}

/* ENDS */